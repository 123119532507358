import { Stack } from '@fluentui/react';
import './animation.css';
import React, { useState, useEffect, ReactNode } from 'react';

const TimedAnimationWrapper = ({ children, hidingItself }: { children: ReactNode, hidingItself: boolean }) => {
  const [animationClass, setAnimationClass] = useState('fade-enter'); // Start invisible

  useEffect(() => {
    setTimeout(() => { setAnimationClass('fade-enter-active'); }, 0);
  }, []);

  return (
    <Stack tokens={{ childrenGap: 20 }} styles={{ root: {position: 'relative'}}} className={hidingItself? 'fade-leave' : animationClass}>
      {children}
    </Stack>
  );
};

export default TimedAnimationWrapper;