import React from 'react';
import { PrimaryButton } from '@fluentui/react';
import  { useState, useEffect } from 'react';
import {  ProgressIndicator } from '@fluentui/react';

interface ProceedButtonProps {
  canProceed: boolean;
  onProceed: () => void;
}

const ProceedButton: React.FC<ProceedButtonProps> = ({ canProceed, onProceed }) => {

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (canProceed) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 0.25);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setProgress(0);
    }
    return;
  }, [canProceed]);

  useEffect(() => {
    if (progress >= 1) {
      onProceed();
    }
  }, [progress, onProceed]);

  return (
    <>
      {canProceed && 
        <>
        <ProgressIndicator label="Proceeding..." percentComplete={progress} />
        <PrimaryButton onClick={onProceed} text="Proceed" disabled={!canProceed} />
        </>
      }
    </>
  );
};

export default ProceedButton;
