import { PrimaryButton, Spinner, Text } from '@fluentui/react';
import React, { useEffect } from 'react';

interface CameraCheckProps {
  cameraStream: MediaStream | null;
  cameraAccess: boolean;
  onCameraCheckPassed: (result: boolean) => void;
}

const CameraCheck: React.FC<CameraCheckProps> = ({ cameraStream, cameraAccess, onCameraCheckPassed }) => {
  useEffect(() => {
    console.log('CameraCheck Rendered with new options.');
  }, [cameraAccess, cameraStream, onCameraCheckPassed]);
  return (
    <>
    {cameraAccess && !cameraStream?.active &&
    <div
    style={{
      padding: '60px',
    }
    }
  >
  <Spinner style={{height: '215px'}} label="Getting camera feed..." />
  </div>}
    
      {cameraStream?.active && (
        <video
          autoPlay
          playsInline
          muted
          style={{ width: '100%', height: '345px', marginTop: 10 }}
          ref={video => {
            if (video && cameraStream) {
              video.srcObject = cameraStream;
            }
          }}
        />
      )}
      {cameraAccess && (
        <>
          <Text variant={'mediumPlus'} style={{margin:5, textAlign: 'left', fontWeight: 500}}>Can you see yourself?</Text>
          <PrimaryButton className="thButton" style={{marginTop:10}} onClick={() => { onCameraCheckPassed(true) }} text="Yes, I can see myself" />
        </>
      )}
    </>
  );
};

export default CameraCheck;
