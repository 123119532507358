// © Microsoft Corporation. All rights reserved.
import { AudioDeviceInfo, VideoDeviceInfo, RemoteVideoStream } from '@azure/communication-calling';
import {
  CommunicationIdentifierKind
} from '@azure/communication-common';
import preval from 'preval.macro';

export type LinkResponse = {
  uri?: string;
  error?: string;
};

export const utils = {
  userCode: window.location.pathname.replace(/\/+$/, '').split('/').pop() || "",
  isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
  isMobileDevice: () => {
    const userAgent = navigator.userAgent.toLowerCase();

    // Check for mobile user agent strings
    const isMobileUA = /android|webos|iphone|ipod|mobile/i.test(userAgent);

    // iPads from iOS 13 onwards use a desktop-class user agent. This checks for iPads using touch capabilities.
    const isiPad = /macintosh/.test(userAgent) && utils.isTouchDevice;

    //override based on query string param mobile=1
    const urlParams = new URLSearchParams(window.location.search);
    const mobileParam = urlParams.get('mobile');
    if (mobileParam) {
      return true;
    }

    return isMobileUA || isiPad;
  },
  isSelectedAudioDeviceInList(selected: AudioDeviceInfo, list: AudioDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  },
  isSelectedVideoDeviceInList(selected: VideoDeviceInfo, list: VideoDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  },
  isMobileSession(): boolean {
    return window.navigator.userAgent.match(/(iPad|iPhone|iPod|Android|webOS|BlackBerry|Windows Phone)/g)
      ? true
      : false;
  },
  isSmallScreen(): boolean {
    return window.innerWidth < 700 || window.innerHeight < 400;
  },
  isUnsupportedBrowser(): boolean {

    const isChrome = /Chrome/i.test(window.navigator.userAgent) && !/Edg/i.test(window.navigator.userAgent);
    // Chrome without Edge
    const isSafari = /Safari/i.test(window.navigator.userAgent) && !/Chrome/i.test(window.navigator.userAgent);
    // Safari without Chrome
    const isEdge = /Edg/i.test(window.navigator.userAgent);

    const isFirefox = /Firefox/i.test(window.navigator.userAgent);
    // window.navigator.userAgent.match(/(Firefox)/g);

    

    //only if it is firefox or if it is not safari chrome or edge
    return (isFirefox || !(isChrome || isSafari || isEdge))
  },
  getId: (identifier: CommunicationIdentifierKind): string => {
    switch (identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
    }
    return '';
  },
  getStreamId: (userId: string, stream: RemoteVideoStream): string => {
    return `${userId}-${stream.id}-${stream.mediaStreamType}`;
  },
  /*
   * TODO:
   *  Remove this method once the SDK improves error handling for unsupported browser.
   */
  isOnIphoneAndNotSafari(): boolean {
    const userAgent = navigator.userAgent;
    // Chrome uses 'CriOS' in user agent string and Firefox uses 'FxiOS' in user agent string.
    if (userAgent.includes('iPhone') && (userAgent.includes('FxiOS') || userAgent.includes('CriOS'))) {
      return true;
    }
    return false;
  },
  getBuildTime: (): string => {
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
    return dateTimeStamp;
  }
};
