import { createTheme } from '@fluentui/react';

export const customTheme = createTheme({
    palette: {
        themePrimary: '#004D99', // CVS Health red
        themeLighterAlt: '#fef2f2',
        themeLighter: '#fbd7d7',
        themeLight: '#f7baba',
        themeTertiary: '#ef7a7a',
        themeSecondary: '#e73a3a',
        themeDarkAlt: '#b30000',
        themeDark: '#990000',
        themeDarker: '#730000',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#a6a6a6',
        neutralSecondary: '#666666',
        neutralPrimaryAlt: '#3c3c3c',
        neutralPrimary: '#333333',
        neutralDark: '#212121',
        black: '#1b1b1b',
        white: '#ffffff',
    },
    fonts: {
        small: {
            fontFamily: 'CVSHealth',
            fontSize: '10px',
            fontWeight: 'normal',
        },
        medium: {
            fontSize: '14px',
            fontFamily: 'CVSHealth',
            fontWeight: 'semibold',
        },
        large: {
            fontSize: '16px',
            fontFamily: 'CVSHealth',
            fontWeight: 'semibold',
        },
        xLarge: {
            fontSize: '18px',
            fontFamily: 'CVSHealth',
            fontWeight: 'bold',
        },
    },
    components: {
        PrimaryButton: {
            styles: {
                borderRadius: '24px',
                minHeight: '44px',
                border: '1px solid #004D99',
                background: '#004D99',
                color: 'white'
            }
        },
    },
});
