import React, { useState, useEffect } from 'react';

const AudioAnimation = ({ animate }: { animate: boolean }) => {
  const [animationPaused, setAnimationPaused] = useState(!animate);

  useEffect(() => {
    setAnimationPaused(!animate);
  }, [animate]);

  const bars = Array.from({ length: 15 }).map(() => ({
    initialHeight: Math.random() * 10 + 10, // Random height between 50px and 100px
    animationDelay: Math.random() * -1, // Random animation delay
  }));

  return (
    <div style={{...styles.container, marginLeft: 20}}>
      {bars.map((bar, index) => (
        <div
          key={index}
          style={{
            ...styles.bar,
            height: `${bar.initialHeight}px`,
            animationDelay: `${bar.animationDelay}s`,
            animationPlayState: animationPaused ? 'paused' : 'running',
          }}
        ></div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px', // Adjust as needed
  },
  bar: {
    width: '5px', // Adjust as needed
    backgroundColor: 'gray',
    margin: '0 2px',
    animation: 'bounce 1s infinite',
    animationTimingFunction: 'linear',
  },
  '@keyframes bounce': {
    '0%, 100%': { height: '50px' },
    '50%': { height: '100px' }, // Adjust max height as needed
  },
};

// Add the keyframes to the document's styles
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes bounce {
    0%, 100% { height: 10px; }
    50% { height: 20px; }
  }
`, styleSheet.cssRules.length);

export default AudioAnimation;
