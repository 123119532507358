import React, { useState } from 'react';
import { Stack, PrimaryButton, Text, TextField } from '@fluentui/react';

// Add backgroundUrl to the component's props type definition and mark it as required
const CollectDisplayName: React.FC<{ nameSubmitted: (name: string) => void; backgroundUrl: string, logoUrl: string }> = ({ nameSubmitted, backgroundUrl, logoUrl }) => {
    const [name, setName] = useState('');

    const handleSubmit = () => {
        nameSubmitted(name);
    };

    // Inline styles for demonstration purposes, including the backgroundUrl for the outer div
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
        width: '100vw', // Full viewport width
        minWidth: 300,
        background: `url(${backgroundUrl})`, 
        backgroundSize: 'cover',
    };

    const whiteCardStyle = {
        margin: '20px',
        backgroundColor: 'transparent',
        borderRadius: '5px',
        padding: '20px',
        //boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={containerStyle}>
            <div style={whiteCardStyle}>
            <Stack tokens={{ childrenGap: 20 }} styles={{ root: { boxShadow: 'rgba(0, 0, 0, 0.7) 0px 8px 16px', position: 'relative', width: '360px', margin: '0 auto', textAlign: 'center', backgroundColor: 'white', borderRadius: '12px', padding: '20px' } }}>
                
            <img src={logoUrl} style={{ height: 24, margin: 'auto', marginTop: 7}}/>
                <Text style={{borderRadius: 6, borderColor: 'lightgray', textAlign: 'left'}} variant="xLarge">Enter your name to continue</Text>
                <TextField onChange={(e) => setName((e.target as any).value)} onKeyDown={(e)=>{ e.key==='Enter' && handleSubmit() }}  />
                <PrimaryButton disabled={name?.length==0} className={'thButton'} onClick={handleSubmit} text="Submit" />
            </Stack>
            </div>
        </div>
    );
};

export default CollectDisplayName;