import help from '../assets/help.svg'
import React from 'react';

const Help : React.FC<{ message: string }> = ({  message }) => {
    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <div style={styles.container as any}>
            <img src={help}/>
            <div>
                <h1 style={styles.errorMessage}>
                    {message}
                </h1>
                Make sure no other app is using your camera and mic.<br/><br/>
                <button
                    onClick={reloadPage}
                    style={styles.retryButton}
                    className='thButton'
                >
                    Retry
                </button>
                
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
        width: '85vw',
        backgroundColor: 'white',
        fontFamily: 'CVSHealth',
        textAlign: 'center',
        padding: '20%',
        margin: 'auto',
        boxSizing: 'border-box',
    },
    errorMessage: {
        fontSize: '24px',
        color: '#004D99', // CVS red
        marginBottom: '20px',
        lineHeight: '1.4',
    },
    retryButton: {
        width: '200px'
    },
};

export default Help;