
  
  function createMockPermissionStatus(state: PermissionState, name: string): PermissionStatus {
    const permissionStatus: PermissionStatus = {
      name,
      state,
      onchange: null,
      addEventListener(type: string, listener: EventListenerOrEventListenerObject | null, options?: boolean | AddEventListenerOptions): void {
        console.log('addEventListener called for', type);
        if (type === 'change' && listener) {
          this.onchange = listener as (this: PermissionStatus, ev: Event) => any;
        }
      },
      removeEventListener(type: string, listener: EventListenerOrEventListenerObject | null, options?: boolean | EventListenerOptions): void {
        console.log('removeEventListener called for', type);
        if (type === 'change' && listener === this.onchange) {
          this.onchange = null;
        }
      },
      dispatchEvent(event: Event): boolean {
        console.log('dispatchEvent called for', event.type);
        if (event.type === 'change' && this.onchange) {
          this.onchange(event);
          return true;
        }
        return false;
      }
    };
    return permissionStatus;
  }
  
  (function overridePermissionsQuery() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'camera' } as any)
        .catch((error) => {
          if (error instanceof TypeError) {
            console.log('Permissions API does not support "camera" out of the box.');
  
            // Save the original method
            const originalQuery = navigator.permissions.query.bind(navigator.permissions);
  
            // Override the query method
            navigator.permissions.query = (descriptor: PermissionDescriptor): Promise<PermissionStatus> => {
              try{
                console.log('Permission Requested for: ' + descriptor.name);
              }catch{}
              
                if (descriptor.name as any === 'camera' || descriptor.name as any === 'microphone') {
                return new Promise((resolve, reject) => {
                  navigator.mediaDevices.getUserMedia({ 
                    video: descriptor.name as any === 'camera', 
                    audio: descriptor.name as any === 'microphone' 
                  })
                  .then((stream) => {
                    console.log('Permission granted for', descriptor.name);
                    const permissionStatus = createMockPermissionStatus('granted', descriptor.name);
                    stream.getTracks().forEach(track => track.stop());
                    resolve(permissionStatus);
                  })
                  .catch((error) => {
                    console.error('Error accessing', descriptor.name, ':', error);
                    if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                      const permissionStatus = createMockPermissionStatus('denied', descriptor.name);
                      resolve(permissionStatus);
                    } else {
                      reject(error);
                    }
                  });
                });
              } else {
                return originalQuery(descriptor);
              }
            };
          }
        });
    }
  })();
  
  export {};
  