import { DefaultButton, PrimaryButton, Text } from '@fluentui/react';
import soundTest from '../../assets/soundTest.mp3';
import React, { useState } from 'react';
import AudioAnimation from './AudioAnimation';

interface SpeakerCheckProps {
  soundTested: boolean;
  onSoundTest: (result: boolean) => void;
  speakerDeviceId: string | undefined;
}

const SpeakerCheck: React.FC<SpeakerCheckProps> = ({ soundTested, onSoundTest, speakerDeviceId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null as HTMLAudioElement | null);




  const playTestSound = async () => {
    const newAudio = new Audio(soundTest)
    if(speakerDeviceId){
      newAudio.setSinkId(speakerDeviceId);
    }
    newAudio.loop = true;
    await newAudio.play().catch(() => {
      completeSoundTest(false);
    });
    setIsPlaying(true);
    setAudio(newAudio);
  };

  const completeSoundTest = (result: boolean) => {
    stopTestSound();
    onSoundTest(true);
  };


  const stopTestSound = () => {
    setIsPlaying(false);
    if (audio) {
      audio.loop = false;
      audio.pause();
      audio.currentTime = 0;
    }
  };

  return (
    <>

      <>
        <div style={{textAlign: 'left', fontSize: 14, fontWeight: 500}}>Click "Play Test Sound" to continue...</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}> {/* Container for the fancy play button */}
            <DefaultButton onClick={isPlaying ? stopTestSound : playTestSound} iconProps={{ iconName: isPlaying? 'Pause' : 'Play' }} text="Play Test Sound" />
          </div>
          <AudioAnimation animate={isPlaying} />
        </div>
        <Text variant={'medium'} style={{ textAlign: 'left', fontSize: 14, fontWeight: 500, minHeight: 30}}>
          {isPlaying && 'A test sound is playing, can you hear it?'}
          </Text>
        <PrimaryButton  className="thButton" onClick={() => completeSoundTest(true)} text="Yes, I can hear it" disabled={!(isPlaying && !soundTested)} />
        <DefaultButton  className="thButton secondary" onClick={() => completeSoundTest(true)} text="I am hearing impaired" disabled={!(!soundTested)} />
      </>
    </>
  );
};

export default SpeakerCheck;
