export type AppConfig = {
    PublicBaseUri: string;
    LoginBaseUri: string;
    AIConnectionString: string;
}

let config: AppConfig | null = null;

export async function getConfig(): Promise<AppConfig> {
    
    if(config == null){
        const result = await fetch("config.json");
        config = (await result.json());
    }

    return config as AppConfig;

}