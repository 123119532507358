import React from 'react';
import { PrimaryButton } from '@fluentui/react';

interface DevicePermissionsPromptProps {
  onPermissionsGranted: () => void;
}

const DevicePermissionsPrompt: React.FC<DevicePermissionsPromptProps> = ({ onPermissionsGranted }) => {
  return (
    <>
      <div style={{fontWeight: 400, marginTop: 5}}>Please allow access to your camera and microphone to continue.</div>
      <PrimaryButton onClick={onPermissionsGranted} className={'thButton'} text="Request Camera and Mic Access" />
    </>
  );
};

export default DevicePermissionsPrompt;
