import React from "react";

const TelehealthCompletionScreen = (props: {logoUrl: string, backgroundUrl?: string }) => {
    return (
        <div style={{...styles.container as any, 
            backgroundUrl: props.backgroundUrl}}>
            
                <div style={styles.icon}><img style={{height: 36}} src={props.logoUrl}></img></div>

            <p style={styles.visitText}>Your Virtual Care visit is complete.</p>
            <p style={styles.visitText}>You may now close this window.</p>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundSize: 'cover',
        fontFamily: 'CVSHealth',
        textAlign: "center",
        padding: "20px"
    },
    iconContainer: {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        backgroundColor: "#CC0000", // CVS red
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds depth to the icon
    },
    icon: {
        fontSize: "48px",
        color: "white",
        lineHeight: "1",
    },
    thankYouText: {
        fontSize: "32px",
        color: "#CC0000", // CVS red
        margin: "10px 0",
        fontWeight: "500", // Provides visual hierarchy
    },
    visitText: {
        fontSize: "18px",
        color: "#333333",
        fontWeight: "400",
        maxWidth: "80%", // Improves readability
        margin: "0 auto", // Centers the text block
    },
};

export default TelehealthCompletionScreen;