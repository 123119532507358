import * as React from 'react';
import { Spinner, Stack } from '@fluentui/react';

interface WaitingForProviderProps {
    backgroundImageUrl?: string; // New prop for background image URL
    label?: string;
}

const WaitingForProvider: React.FC<WaitingForProviderProps> = ({ backgroundImageUrl, label="Waiting for Virtual Care Visit to start..." }) => {
    const background = backgroundImageUrl ? `url(${backgroundImageUrl})` : '#FFFFFF00';
    return (
        // Fullscreen background container
        <div style={{
            position: 'fixed', // Use fixed to cover the entire screen
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: background,
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        }}>
            {/* White card with rounded borders */}
            <div style={{
                margin: '20px',
                minWidth: '250px',
                backgroundColor: 'white',
                borderRadius: '12px',
                padding: '60px', // Add some padding inside the card
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a shadow for better contrast
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Stack horizontalAlign="center" verticalAlign="center">
                    <Spinner label={label} />
                </Stack>
            </div>
        </div>
    );
};

export default WaitingForProvider;