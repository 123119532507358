export type ErrorMessages = {
    FailedtofetchError: string
    GenericFetchError: string
    VisitCompleteError: string
    InvalidVisitError: string    
    /**Composite group errors  */

    TimeOutError: string
    CallFailedError: string
    BrowerCompatabilityError:string

}

const errorMessages: ErrorMessages = {
    FailedtofetchError: "Something went wrong. It looks like we’re having technical issues to join. Please try again or contact Customer Care at 1-877-993-4321.",
    GenericFetchError: "Something went wrong. It looks like we’re having technical issues. Please try again or contact Customer Care at 1-877-993-4321.",
    VisitCompleteError: "This visit has already occurred. The link you used is no longer active.",
    InvalidVisitError: "We couldn’t find your visit. The link you used is not valid.",    
    TimeOutError: "Your provider seems to be unavailable. Since your provider hasn’t joined your virtual visit after 15 minutes, we recommend scheduling a new visit.",
    CallFailedError: 'Call Failed: ',
    BrowerCompatabilityError: "This browser is not suppported. To join your visit, please use Edge, Chrome, or Safari and try again."
}

export default errorMessages;