import React from 'react';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  const sentences = message.split('.');

  return (
    <div
    style={{
      marginTop: '24px',
      border:'2px solid #CC0000',
      padding: '10px',
      paddingLeft: 46,
      borderRadius: '8px',
      position: 'relative'
    }}
    >
      <div
      style={{
        position: 'absolute',
        background: '#CC0000',
        color: 'white',
        top: 0,
        left: 0,
        bottom: 0,
        width: 36,
        paddingLeft: 5,
        paddingTop:5
      }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <circle cx="12" cy="12" r="10" stroke="white" stroke-width="2" fill="none" />
        <text x="12" y="16" text-anchor="middle"  font-size="12" font-family="CVSHealth" fill="white">!</text>
        </svg>
      </div>
      <div style={{paddingLeft:5, fontWeight: 700, marginBottom: 5, fontSize: 18}}>
      {sentences[0] + "."}
      </div>
      {sentences.length > 1 &&
      <div style={{paddingLeft:5}}>
      {message.slice(message.indexOf('.') +1)}
      </div>
      }
    </div>
  );
};

export default ErrorMessage;
